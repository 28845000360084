@import url("https://fonts.googleapis.com/css2?family=LXGW+WenKai+Mono+TC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap");

* {
  font-family: "LXGW WenKai Mono TC", monospace;
  letter-spacing: 0.3rem;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #f2d6b1 !important;
  font-size: 1.4rem;
  line-height: 1.6;
}

h1 {
  font-size: clamp(1.8rem, 5vw, 4rem) !important;
  letter-spacing: 0.6rem;
  margin-bottom: 1rem !important;
  line-height: 1.8 !important;
  font-weight: 900 !important;
  font-family: "Playwrite GB S", cursive;
  font-optical-sizing: auto;
  color: #914525 !important;
}

h2 {
  font-size: clamp(1.2rem, 4vw, 3rem) !important;
  color: #914525;
}

h3 {
  font-size: clamp(1.2rem, 4vw, 3rem) !important;
  font-weight: 900 !important;
  color: #914525 !important;
}

p {
  font-size: clamp(1.2rem, 4vw, 3rem) !important;
  line-height: 1.4;
  color: #914525 !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  html {
    font-size: 56%;
  }

  h1 {
    font-size: clamp(2.5rem, 4vw, 3.5rem);
    line-height: 2;
  }

  h2 {
    font-size: clamp(2rem, 3vw, 3rem);
  }

  h3 {
    font-size: clamp(1.8rem, 3vw, 2.5rem);
  }

  p {
    font-size: clamp(1.6rem, 3vw, 2rem);
    line-height: 1.6;
  }

  .logoNavBar {
    width: 15rem;
    height: 5rem;
  }

  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end !important;
  }
  .navbar-expand-lg .navbar-nav {
    gap: 2rem !important;
  }

  .HomeContent {
    padding: 15rem 18rem;
  }

  .HomeImage1 {
    width: 18rem !important;
  }

  .HomeImage2 {
    width: 13rem !important;
  }

  .MenuCard {
    margin-top: 2.5rem !important;
  }

  .KathmanduImg {
    height: 12rem !important;
  }
  .footerLogo {
    width: 18rem !important;
}
}

@media (min-width: 1025px) {
  html {
    font-size: 62.5%;
  }

  h1 {
    letter-spacing: 0.4rem;
    margin-bottom: 0.6rem;
    font-size: clamp(3rem, 5vw, 3rem);
    line-height: 2.2;
  }

  h2 {
    font-size: clamp(2rem, 2.5vw, 3.5rem) !important;
  }

  h3 {
    font-size: clamp(2rem, 2.5vw, 3rem) !important;
  }

  p {
    font-size: clamp(1.8rem, 2.5vw, 2.2rem) !important;
    line-height: 1.8 !important;
  }

  .logoNavBar {
    width: 15rem;
    height: 5rem;
  }

  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end !important;
  }

  .navbar-expand-lg .navbar-nav {
    gap: 3rem !important;
  }

  .HomeContent {
    padding: 20rem 25rem !important;
  }

  .HomeImage1 {
    width: 20rem !important;
  }

  .HomeImage2 {
    width: 15rem !important;
  }

  .front {
    font-size: clamp(1.8rem, 2.5vw, 2.2rem) !important;
  }

  .popularDishesItemsImage {
    width: 21rem !important;
    height: 20rem !important;
  }

  .popularDishesItemsContainer {
    gap: 10rem !important;
  }

  .nav {
    gap: 3rem;
  }

  .MenuCard {
    width: 55rem !important;
  }

  .tab-content>.active {
    justify-content: space-between !important;
    margin-top: 2rem !important;
  }

  .menuSectionBottomImg {
    height: 15rem !important;
  }

  .KathmanduImg {
    height: 15rem !important;
  }

  .footerLogo {
    width: 20rem !important;
    padding: 5rem 0 !important;
  }
}