.popularDishesItems{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.popularDishesItemsImage{
  width: 11rem;
  height: 10rem;
}
.popularDishesItemsImage img{ 
  width: 100%;
  height: 100%;
}
.popularDishesItemsDesc{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}