.popularDishes{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2d6b1 ;
  flex-direction: column;
  margin-top: 3rem;
}
.popularDishesItemsContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  gap: 3rem;
}