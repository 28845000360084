.Menu{
  background-color: #f2d6b1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}
.Menu .nav{
  align-items: center;
  justify-content: center;
  --bs-nav-link-color:#d35521;
  font-weight: 900;
}
.Menu .nav-tabs{
--bs-nav-tabs-border-color: #d35521;
margin-top: 2rem;
}
.Menu .nav-link.active, .Menu .nav-link:hover{
  background-color: #d35521 !important;
  color: #f4d7b2 !important;
  border: none;
}
.Menu .nav-link{
  color: #d35521 !important;
  border: none;
}
.Menu .tab-content>.active {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}
@media (max-width: 768px) {
  .tab-content>.active {
    justify-content: space-around;
  }
}
@media (max-width: 1024px) {
  .tab-content>.active {
    justify-content: space-around;
  }
}