.navbar{
  background-color: #f2d6b1 ;
  box-shadow: rgba(0, 0, 0, 30%) 0rem 2.5rem 20rem;
  z-index: 1;
  /* padding: 1rem 3rem; */
}
.logoNavBar{
  width: 18rem;
  height: 7rem;
  padding: .8rem 0;
}
.logoNavBar img{
  width: 100%;
  height: 100%;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: center;
  margin-right: 3rem;
}


.navbar .nav-link{
  color: #d35521 !important;
  font-size: large;
}
.navbar .nav-link:hover{
  border-bottom: .2rem solid #d35521;
}
