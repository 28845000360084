.footerMain{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.KathmanduImg{
  margin-top: 2rem;
  width: 50%;
  height: 5rem;
}
.KathmanduImg img{
  width: 100%;
  height: 100%;
}
.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #d35521 ;
  color: #f2d6b1 !important;
  z-index: 1;
  width: 100%;
}
.footerLogo{
  width: 15rem;
  height: auto;
  padding: 2rem 0;
}
.footerLogo img{
  width: 100%;
  height: 100%;
}
.footerLink{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.footerLink a{
  text-decoration: none;
  color: #f2d6b1;
}
.footerLocation{
  margin: 2rem 0;
}
.footerLocationSub{
  display: flex;
  gap:  1rem;
  margin-bottom: 1rem;
  letter-spacing: 1rem;
}
.footerLocationSub a{
  color: #f2d6b1;
  text-decoration: none;
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
}
.footerLocationSub .FontIcon{
  color: #f2d6b1 !important;
}
.footerIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}
.footerIcon a{
  color: #f2d6b1 !important;
}
.footerBottom{
  margin-top: 2rem;
}
.footerBottom p{
  font-size: .8rem;
  letter-spacing: .1rem;
  color: #f2d6b1 !important;
}