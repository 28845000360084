.MenuCard{
  width: 90%;
  margin-top: 1rem;
}
.MenuCardItemsTop{
  display: flex;
  justify-content: space-between;
}
.MenuCardItemsTop h2{
  font-style: italic;
  letter-spacing: 0;
  font-weight: 900;
  color: #914525;
}
.MenuCardItemsBottom p{
  letter-spacing: 0;
  margin-bottom: 0;
}