.Home{
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2d6b1;
  position: relative;
}
.HomeImage1{
  position: absolute;
  width: 10rem;
  height: auto;
  left: -3rem;
}
.HomeImage1 img{
  width: 100%;
  height: 100%;
  border-radius: 0 5rem 5rem 0;
}
.HomeImage2{
  position: absolute;
  width: 7rem;
  height: auto;
  right: 0;
}
.HomeImage2 img{
  width: 100%;
  height: 100%;
}
.HomeContent{
  width: 100%;
  padding: 3rem 8rem;
  z-index: 1;
  text-align: center;
}
.HomeContent p{
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
  line-height: 2;
}
.Button {
  position: relative;
  background: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  outline-offset: .4rem;
  outline-color: rgb(0, 0, 0);
  transition: filter 250ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: hsl(0, 25%, 69%);
  border-radius: 8px;
  filter: blur(2px);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
    to right,
    hsl(11, 39%, 39%) 0%,
    hsl(12, 39%, 49%) 8%,
    hsl(7, 39%, 39%) 92%,
    hsl(7, 39%, 29%) 100%
  );
}
.front {
  display: block;
  position: relative;
  border-radius: .8rem;
  background: #d15826;
  padding: 1rem 2rem;
  color: rgb(244, 244, 244);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-size: 1.4rem;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.Button:hover {
  filter: brightness(110%);
}
.Button:hover .front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.Button:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.Button:hover .shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.Button:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}
.Button:focus:not(:focus-visible) {
  outline: none;
}

